import React, { useState } from 'react';

// carousel
import ReactSimplyCarousel from 'react-simply-carousel';

//subcomponents
import CarouselItemProject from './common/CarouselItemProject';

//item components
import number_one from '../../../static/svg/projects-number-1.svg';
import number_two from '../../../static/svg/projects-number-2.svg';
import number_three from '../../../static/svg/projects-number-3.svg';
import banner_one from '../../../static/svg/projects-banner.svg';
import banner_vrcity from '../../../static/svg/projects-banner-vrcity.svg';
import banner_equipment_monitoring from '../../../static/svg/projects-banner-equipment-monitoring.svg';

export const SolutionsProjects = () => {
    //states
    const [itemValue, setItemValue] = useState(0);

    const styles = {
        CarouselButtonColor: {
            color: 'white',
        },
        CarouselButtonPrevious: {
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            padding: '5px',
            background: '#9ba7ab',
            cursor: 'pointer',
            borderRadius: '5px',
            width: '30px',
            margin: "0 auto",
            position: "absolute",
            zIndex: 10,
            left: 40
        },
        CarouselButtonNext: {
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            padding: '5px',
            background: '#9ba7ab',
            cursor: 'pointer',
            borderRadius: '5px',
            width: '30px',
            margin: "0 auto",
            position: "absolute",
            zIndex: 10,
            right: 40
        },
    };

    //dummy data
    const carouselItem = [
        {
            img: number_one,
            titleA: 'IOT & SOFTWARE DEVELOPMENT',
            titleB: 'Equipment Monitoring',
            link: '',
            desc: [
                'Real-time equipment status monitoring via phone or web 24/7',

                'Can send alerts about abnormal readings of sensors',

                'Records reading of ambient temperature, current, and voltage',
            ],
            banner: banner_one,
        },
        {
            img: number_two,
            titleA: 'SOFTWARE DEVELOPMENT',
            titleB: 'VR City Web Configurator',
            link: 'https://configurator.vrcity.space/',
            desc: [
                'Web application for configuring settings for VR City Mobile & Desktop Applications',

                'Used to create virtual stores and manage products',
            ],
            banner: banner_vrcity,
        },
        {
            img: number_three,
            titleA: 'IOT & SOFTWARE DEVELOPMENT',
            titleB: 'Factory Monitoring',
            link: '',
            desc: [
                'Monitors the temperature, humidity, and people count',

                'Alerts maintenance or security for any anomalies found inside the the factories based on the factors being monitored',
            ],
            banner: banner_equipment_monitoring,
        },
    ];

    return (
        <>
            <section className="project d-flex-col align-items center jutify-content-center">
                <div className="project_carousel">
                    <ReactSimplyCarousel
                        containerProps={{
                            style: {
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                userSelect: 'text',
                            },
                        }}
                        autoplay
                        autoplayDelay={3000}
                        activeSlideIndex={itemValue}
                        onRequestChange={setItemValue}
                        forwardBtnProps={{
                            children: <span style={styles.CarouselButtonColor}>&gt;</span>,
                            style: styles.CarouselButtonNext,
                        }}
                        backwardBtnProps={{
                            children: <span style={styles.CarouselButtonColor}>&lt;</span>,
                            style: styles.CarouselButtonPrevious,
                        }}
                        dotsNav={{
                            show: true,
                            itemBtnProps: {
                                style: {
                                    height: 4.5,
                                    width: 27,
                                    border: 0,
                                    opacity: 0.5,
                                    margin: '30px 5px 0px 5px',
                                    background: 'rgb(251, 157, 80)',
                                    cursor: 'pointer',
                                },
                            },
                            activeItemBtnProps: {
                                style: {
                                    height: 4.5,
                                    width: 27,
                                    border: 0,
                                    opacity: 1,
                                    margin: '30px 5px 0px 5px',
                                    background: 'rgb(251, 157, 80)',
                                    cursor: 'pointer',
                                },
                            },
                        }}
                        itemsToShow={1}
                        speed={1000}
                        easing="ease-out"
                        centerMode
                    >
                        {carouselItem.map((data, i) => (
                            <CarouselItemProject key={i} data={data} />
                        ))}
                    </ReactSimplyCarousel>
                </div>
            </section>
        </>
    );
};
