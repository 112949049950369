import React, { useEffect } from 'react';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import img_bgb from '../../../static/svg/roles-bground-right.svg';

gsap.registerPlugin(ScrollTrigger);

export const SolutionsBanner = () => {
    useEffect(() => {
        gsap.from('.gsap_banner', {
            duration: 1,
            y: '10',
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.solutionbanner',
                start: 'top 100%',
                end: 'bottom 30%',
                toggleActions: 'play',
            },
            stagger: 0.2,
        });
    }, []);

    return (
        <>
            <section
                className="solutionbanner d-flex-col align-items-center justify-content-center"
                name="banner"
            >
                <div className="solutionbanner_main d-flex-col align-items-center justify-content-center">
                    <p className="gsap_banner display_3 text-center">
                        Intelligent, modern, & effective
                        <span className="text-orange"> solutions </span>
                    </p>
                    <p className="gsap_banner text-center">
                        Let us help you elevate your business idea into more efficient and
                        modernized processes.
                    </p>
                </div>
                <div>
                    <img src={img_bgb} alt="Card Background" className='aboutbanner-shape-1' />
                    <img src={img_bgb} alt="Card Background" className='aboutbanner-shape-2' />
                </div>
            </section>
        </>
    );
};
