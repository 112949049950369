import React, { useEffect } from 'react';
import { Button } from 'antd';
import contact_hero from '../../../static/svg/build-hero.svg';
import img_bga from '../../../static/svg/build-bground-left.svg';
import img_bgb from '../../../static/svg/build-bground-right.svg';
//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const SolutionsBuild = () => {
    useEffect(() => {
        gsap.from('.gsap_build_solutions_img', {
            duration: 1,
            y: '10',
            scale: 0.6,
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.build_solutions',
                start: 'top 40%',
                end: 'bottom 50%',
                // markers: true,
                toggleActions: 'play none none reverse',
                // delay: 0.5,
            },
            stagger: 0.2,
        });
        gsap.from('.gsap_build_solutions_text', {
            duration: 1,
            y: '10',
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.build_solutions',
                start: 'top 75%',
                end: 'bottom 35%',
                // markers: true,
                toggleActions: 'play none none reverse',
                // delay: 0.5,
            },
            stagger: 0.2,
        });
    }, []);
    //
    return (
        <>
            <section className="build d-flex-col align-items-center justify-content-center build_solutions">
                <div className="build_container d-flex-row align-items-center justify-content-center">
                    <div className="build_container_text d-flex-col gap-1">
                        <h2 className="gsap_build_solutions_text">
                            Got an <span> awesome </span> & <span> creative </span> idea?
                        </h2>
                        <p className="gsap_build_solutions_text">
                            Let's work on it together! Schedule a call or send us your requirements.
                            And we'll get back to you as soon as we can.
                        </p>
                        <div className="gsap_build_solutions_text">
                            {/* <Button type="primary">Build My Idea</Button> */}
                        </div>
                    </div>
                    <img
                        src={contact_hero}
                        alt="Contact Us Hero"
                        className="gsap_build_solutions_img"
                    />
                </div>
                <div className="build_bground d-flex-row align-items-center justify-content-between">
                    <img src={img_bga} alt="Card Background" />
                    <img src={img_bgb} alt="Card Background" />
                </div>
            </section>
        </>
    );
};
