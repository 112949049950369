import React from 'react';
import { Card } from 'antd';

// css media query
import '../../../../media-query-global.css';

// bullet
import bullet from '../../../../static/svg/projects-bullet.svg';

// icons
import { TbWorld } from 'react-icons/tb';

const CarouselItemProject = (data) => {
    const { data: initData } = data;
    const { img, titleA, titleB, desc, banner, link } = initData;

    return (
        <Card bordered={false}>
            <section className="carousel_container d-flex-row align-items-center justify-content-center">
                <div className="carousel_container_left d-flex-col gap-1">
                    <img src={img} alt="Number Img" />
                    <div className="title">
                        <p>{titleA}</p>
                        <h2>{titleB}</h2>
                        {link && (
                            <div className="home_link_container">
                                <TbWorld />
                                <a href={link} target="_blank" className="home_link_text">
                                    {link}
                                </a>
                            </div>
                        )}
                    </div>
                    <div className="descriptions d-flex-col">
                        {desc.map((des, i) => (
                            <div key={i} className="d-flex-row gap-1 carousel-desc">
                                <img src={bullet} alt="Bullets" />
                                {des}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="carousel_container_right d-flex-col align-items-center justify-content-center">
                    <img src={banner} alt={`Banner ${data.index + 1}`} />
                </div>
            </section>
        </Card>
    );
};

export default CarouselItemProject;
